import { useApp } from '@/hooks/useApp'
import { cursor, type CursorKeyType } from '@/styles/cursor'
import { fontSize, fontWeight } from '@/styles/font'
import { Block } from '@/components/base/Block'
import { Icon, LocalIconType } from '@/components/base/Icon'
import { display, align } from '@/styles/display'
import toast, { Toaster, ToastBar } from 'react-hot-toast'
import useIsMobile from '@/hooks/useMobile'
import { type V1IconType } from 'my-recruiter-app-ts-if'

interface Props {
  onClickCloseIcon: () => void
}

interface IconProps {
  onClick?: () => void
  color?: string
  iconType?: V1IconType | LocalIconType
  cursor?: CursorKeyType
}

const showToast = (message: string): string =>
  toast(message, {
    duration: 4000
  })

const showErrorToast = (message: string): string =>
  toast.error(message, {
    duration: 4000
  })

const BaseSnackBar = ({ onClickCloseIcon }: Props): JSX.Element => {
  const app = useApp()
  const isMobile = useIsMobile()

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        icon: (
          <BaseSnackBarIcon
            iconType={LocalIconType.Done}
            color={app.theme.primaryMainColor.color}
          />
        ),
        style: {
          minWidth: isMobile ? '95%' : '720px',
          backgroundColor: app.theme.primaryUltraLightColor.color,
          color: app.theme.primaryMainColor.color
        },
        error: {
          icon: (
            <BaseSnackBarIcon
              iconType={LocalIconType.Close}
              color={app.theme.errorMainColor.color}
            />
          ),
          style: {
            backgroundColor: app.theme.errorUltralightColor.color,
            color: app.theme.errorMainColor.color
          }
        }
      }}
    >
      {(t) => (
        <ToastBar style={{ ...t.style }} toast={t}>
          {({ icon, message }) => (
            <div style={{ flex: 1 }}>
              <Block display={display.FLEX} alignItems={align.CENTER}>
                {icon ?? <BaseSnackBarIcon color={t.style?.color} />}
                <div
                  style={{
                    textAlign: align.LEFT,
                    display: display.FLEX,
                    flexGrow: 1,
                    justifyContent: align.FLEX_START
                  }}
                >
                  <div
                    style={{
                      fontSize: fontSize.M,
                      fontWeight: fontWeight.Bold,
                      color: t.style?.color
                    }}
                  >
                    {message}
                  </div>
                </div>
                {t.type !== 'loading' && (
                  <BaseSnackBarIcon
                    onClick={() => {
                      toast.dismiss(t.id)
                      onClickCloseIcon()
                    }}
                    iconType={LocalIconType.Close}
                    color={t.style?.color}
                    cursor={cursor.POINTER}
                  />
                )}
              </Block>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

const BaseSnackBarIcon = ({
  onClick,
  color,
  iconType,
  cursor
}: IconProps): JSX.Element => {
  const app = useApp()

  return (
    <Icon
      onClick={onClick}
      iconType={iconType ?? LocalIconType.Done}
      iconStyle={{
        theme: {
          color: color ?? app.theme.primaryMainColor.color
        },
        fontSize: fontSize.M,
        cursor
      }}
    />
  )
}

export { showToast, showErrorToast }
export default BaseSnackBar
