import { type CompanyItem, defaultCompanys } from '../components/Company'
import EntryContainer from '../containers/EntryContainer'

function TermOfUsePage(): JSX.Element {
  const terms = [
    'https://kango.mynavi.jp/kiyaku/',
    'https://pharma.mynavi.jp/medical_result/kiyaku.html',
    'https://co-medical.mynavi.jp/kiyaku/',
    'https://kaigoshoku.mynavi.jp/kiyaku/',
    'https://hoiku.mynavi.jp/kiyaku/'
  ]

  const newArray: CompanyItem[] = defaultCompanys.map((value, index) => {
    return { ...value, url: terms[index] }
  })

  return (
    <EntryContainer
      title="利用規約"
      subTitle={`各サービスの「利用規約」は以下よりご参照ください`}
      items={newArray}
    />
  )
}

export default TermOfUsePage
