import { type V1AppColorTheme, type V1IconType } from 'my-recruiter-app-ts-if'
import { Icon, type LocalIconType } from '../Icon'
import styled from 'styled-components'
import { type FontSizeKeyType } from '@/styles/font'

const Button = styled.button`
  border: none;
  background: transparent;
`

interface Props {
  icon: LocalIconType | V1IconType
  fontSize?: FontSizeKeyType
  color?: V1AppColorTheme
  onClick: () => void
}

const IconButton = ({ icon, onClick, fontSize, color }: Props): JSX.Element => {
  return (
    <Button type="button" onClick={onClick}>
      <Icon iconType={icon} iconStyle={{ fontSize, theme: color }} />
    </Button>
  )
}

export default IconButton
