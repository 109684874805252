import { jobPostingServiceApi } from '@/api/job-posting-service-api'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import {
  type JobPostingServiceNotifyFavoriteStatusChangeRequest,
  type FetchError
} from 'my-recruiter-app-ts-if'
import { type MutationConfig } from '@/lib/react-query'

export const jobPostingServiceNotifyFavoriteStatusChange = async (
  requestParameters: JobPostingServiceNotifyFavoriteStatusChangeRequest
): Promise<object> => {
  return await jobPostingServiceApi.jobPostingServiceNotifyFavoriteStatusChange(
    requestParameters
  )
}

interface UseJobPostingServiceNotifyFavoriteStatusChangeOptions {
  config?: MutationConfig<typeof jobPostingServiceNotifyFavoriteStatusChange>
}

export const jobPostingServiceNotifyFavoriteStatusChangeMutationKey: string =
  'job_posting_service_notify_favorite_status_change'

export const useJobPostingServiceNotifyFavoriteStatusChange = ({
  config
}: UseJobPostingServiceNotifyFavoriteStatusChangeOptions = {}): UseMutationResult<
  object,
  FetchError,
  JobPostingServiceNotifyFavoriteStatusChangeRequest,
  unknown
> => {
  return useMutation({
    mutationKey: [jobPostingServiceNotifyFavoriteStatusChangeMutationKey],
    onError: (err) => {
      return err
    },
    ...config,
    mutationFn: jobPostingServiceNotifyFavoriteStatusChange
  })
}
