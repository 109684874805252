import { memo } from 'react'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the loading overlay appears on top of other content */
`

const LoadingContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
`

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db; /* Change the color of the spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinAnimation} 2s linear infinite;
  margin-bottom: 10px;
`

export const Loading = memo((): JSX.Element => {
  return (
    <LoadingOverlay>
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    </LoadingOverlay>
  )
})

Loading.displayName = 'Loading'
