export const display = {
  BLOCK: 'block',
  FLEX: 'flex',
  INLINE_BLOCK: 'inline-block',
  GRID: 'grid',
  NONE: 'none'
} as const

export type DisplayKeyType = (typeof display)[keyof typeof display]

export const align = {
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  STRETCH: 'stretch',
  BASELINE: 'baseline',
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right'
} as const

export type AlignKeyType = (typeof align)[keyof typeof align]
