import { fontSize, fontWeight } from '@/styles/font'
import BaseText from './BaseText'
import { type TextProps } from './props'

const Text2 = (props: TextProps): JSX.Element => {
  return (
    <BaseText fontSize={fontSize.M} fontWeight={fontWeight.Regular} {...props}>
      {props.children}
    </BaseText>
  )
}

export default Text2
