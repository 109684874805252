import { Block } from '@/components/base/Block'
import { Row } from '@/components/base/Layout'
import Logo from '@/components/base/Logo/Logo'
import useIsMobile from '@/hooks/useMobile'
import AppAssets from '@/styles/app_assets'
import { type AlignKeyType } from '@/styles/display'
import { memo } from 'react'
import styled from 'styled-components'

export const defaultCompanys: CompanyItem[] = [
  {
    url: 'https://kango.mynavi.jp/entry/',
    logo: AppAssets.logoKango,
    name: 'kango'
  },
  {
    url: 'https://pharma.mynavi.jp/entry2/index.html',
    logo: AppAssets.logoPharma,
    name: 'pharma'
  },
  {
    url: 'https://co-medical.mynavi.jp/entry/',
    logo: AppAssets.logoCoMedical,
    name: 'co-medical'
  },
  {
    url: 'https://kaigoshoku.mynavi.jp/application/input-chat/',
    logo: AppAssets.logoKaigoshoku,
    name: 'kaigoshoku'
  },
  {
    url: 'https://hoiku.mynavi.jp/entry/?Linkid=header_entry_CVpc',
    logo: AppAssets.logoHoiko,
    name: 'hoiku'
  }
]

const CompanyContainer = styled.div``
export interface CompanyItem {
  url: string
  logo: string
  name: string
}

interface Props {
  url: string
  logo: string
  name: string
  children?: React.ReactNode
  justify?: AlignKeyType
}

const Company = ({
  url,
  logo,
  name,
  children,
  justify
}: Props): JSX.Element => {
  const isMobile = useIsMobile()
  return (
    <CompanyContainer key={name}>
      <Row justify={justify ?? 'center'} align="flex-end">
        <Block display="flex" maxWidth="385px" justifyContent={'flex-start'}>
          <Logo
            url={url}
            imageUrl={logo}
            name={name}
            height={isMobile ? 56 : 68}
          />
        </Block>
        {children}
      </Row>
    </CompanyContainer>
  )
}

export default memo(Company)
