import { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Image = styled.img`
  object-fit: contain;
`

interface Props {
  url: string
  imageUrl: string
  name?: string
  width?: number
  height?: number
}

const Logo = ({ url, name, width, height, imageUrl }: Props): JSX.Element => {
  return (
    <Link to={url}>
      <Image src={imageUrl} alt={name} width={width} height={height} />
    </Link>
  )
}

export default memo(Logo)
