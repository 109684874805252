export const fontSize = {
  XXS: '10px',
  XS: '12px',
  S: '14px',
  M: '16px',
  L: '18px',
  XL: '21px',
  XXL: '24px',
  HERO: '44px'
} as const

export type FontSizeKeyType = (typeof fontSize)[keyof typeof fontSize]

export const fontWeight = {
  Bold: '700',
  Regular: '400'
} as const

export type FontWeightType = (typeof fontWeight)[keyof typeof fontWeight]

export const fontStyle = {
  Italic: 'italic',
  Normal: 'normal'
} as const

export type FontStyleType = (typeof fontStyle)[keyof typeof fontStyle]
