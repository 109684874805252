import spaceStyle, { buildSpaceStyle } from '@/styles/space'
import styled from 'styled-components'
import type React from 'react'
import { memo } from 'react'
import { type BlockProps } from './props'
import textAlign from '@/styles/text_align'
import { fontSize, fontWeight } from '@/styles/font'
import borderRadius, { buildBorderRadiusStyle } from '@/styles/border_radius'
import { buildBorderStyle, commonBorder } from '@/styles/border'
import { display } from '@/styles/display'
import { size } from '@/styles/device'
import { useApp } from '@/hooks/useApp'
import { isBoolean } from 'lodash'

const Styled = styled.div<BlockProps>`
  display: ${(props) =>
    props.display !== undefined ? props.display : display.BLOCK};
  align-items: ${(props) =>
    props.alignItems !== undefined ? props.alignItems : undefined};
  justify-content: ${(props) =>
    props.justifyContent !== undefined ? props.justifyContent : undefined};
  font-size: ${(props) =>
    props.fontSize !== undefined ? props.fontSize : fontSize.M};
  font-weight: ${(props) =>
    props.fontWeight !== undefined ? props.fontWeight : fontWeight.Regular};
  text-align: ${(props) => {
    return props.textAlign !== undefined ? props.textAlign : textAlign.CENTER
  }};
  margin: ${(props) => {
    return props.margin !== undefined
      ? buildSpaceStyle(props.margin)
      : spaceStyle.NONE
  }};
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : spaceStyle.NONE
  }};
  border-radius: ${(props) =>
    props.borderRadius !== undefined
      ? buildBorderRadiusStyle(props.borderRadius)
      : borderRadius.NONE};
  background-color: ${(props) => {
    return props.backgroundColor?.color !== undefined
      ? props.backgroundColor?.color
      : 'none'
  }};
  border: ${(props) => {
    return props.border !== undefined && !isBoolean(props.border)
      ? buildBorderStyle(props.border)
      : ''
  }};
  border-top: ${(props) => {
    return props.borderTop !== undefined && !isBoolean(props.borderTop)
      ? buildBorderStyle(props.borderTop)
      : ''
  }};
  border-bottom: ${(props) => {
    return props.borderBottom !== undefined && !isBoolean(props.borderBottom)
      ? buildBorderStyle(props.borderBottom)
      : ''
  }};
  max-width: ${(props) => props.maxWidth !== undefined && props.maxWidth};
  width: ${(props) => props.width !== undefined && props.width};
  height: ${(props) => props.height !== undefined && props.height};
  position: relative;
  @media only screen and (max-width: ${size.mobile}) {
    ${(props: BlockProps) => props.laptop === true && 'display: none;'};
  }

  @media only screen and (min-width: ${size.mobile}) {
    ${(props: BlockProps) => props.mobile === true && 'display: none;'};
  }

  ${(props: BlockProps) =>
    props.cursor !== undefined && `cursor: ${props.cursor};`};
`

const Block = (props: BlockProps): React.ReactElement => {
  const { theme } = useApp()
  const borderBottom =
    isBoolean(props.borderBottom) && props.borderBottom
      ? { ...commonBorder, color: theme.frameColor }
      : props.borderBottom

  const borderTop =
    isBoolean(props.borderTop) && props.borderTop
      ? { ...commonBorder, color: theme.frameColor }
      : props.borderTop

  const border =
    isBoolean(props.border) && props.border
      ? { ...commonBorder, color: theme.frameColor }
      : props.border

  return (
    <Styled
      {...props}
      borderBottom={borderBottom}
      border={border}
      borderTop={borderTop}
    >
      {props.children}
    </Styled>
  )
}

export default memo(Block)
