import { memo } from 'react'
import Base, { type ButtonProps } from './Base'
import { type App, useApp } from '@/hooks/useApp'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import borderLine, { borderSize } from '@/styles/border'

interface Colors {
  bgColor: V1AppColorTheme
  textColor: V1AppColorTheme
  borderColor?: V1AppColorTheme
}

const getColors = (props: ButtonProps, app: App): Colors => {
  const bgColor = props.bgColor ?? app.theme.primaryMainColor
  const textColor = props.textColor ?? app.theme.whiteTextColor
  if (props.outlined ?? false) {
    return {
      bgColor: app.theme.whiteColor,
      textColor: bgColor,
      borderColor: bgColor
    }
  }
  return { bgColor, textColor, borderColor: undefined }
}

const Primary = (props: ButtonProps): JSX.Element => {
  const app = useApp()
  const { bgColor, textColor, borderColor } = getColors(props, app)
  const border =
    borderColor !== undefined
      ? { size: borderSize.S, line: borderLine.Solid, color: borderColor }
      : undefined
  return (
    <Base bgColor={bgColor} textColor={textColor} border={border} {...props} />
  )
}

export default memo(Primary)
