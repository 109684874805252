const spaceStyle = {
  NONE: '0',
  AUTO: 'auto',
  XXS: '2px',
  XS: '4px',
  S: '8px',
  M: '12px',
  L: '16px',
  LL: '20px',
  XL: '24px',
  XXL: '28px',
  XXXL: '32px',
  XXXXL: '46px',
  XXXXXL: '56px'
} as const

export type SpaceKeyType = (typeof spaceStyle)[keyof typeof spaceStyle]

export interface SpacingProps {
  all?: SpaceKeyType
  top?: SpaceKeyType
  bottom?: SpaceKeyType
  right?: SpaceKeyType
  left?: SpaceKeyType
  horizontal?: SpaceKeyType
  vertical?: SpaceKeyType
}

export const buildSpaceStyle = (props: SpacingProps): string => {
  if (props.all !== undefined) {
    return `${props.all} ${props.all} ${props.all} ${props.all}`
  }

  const marginTop =
    props.vertical !== undefined
      ? props.vertical
      : props.top !== undefined
        ? props.top
        : '0'
  const marginBottom =
    props.vertical !== undefined
      ? props.vertical
      : props.bottom !== undefined
        ? props.bottom
        : '0'
  const marginRight =
    props.horizontal !== undefined
      ? props.horizontal
      : props.right !== undefined
        ? props.right
        : '0'
  const marginLeft =
    props.horizontal !== undefined
      ? props.horizontal
      : props.left !== undefined
        ? props.left
        : '0'

  return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`
}

export default spaceStyle
