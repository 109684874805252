import borderRadius from '@/styles/border_radius'
import { type FontSizeKeyType } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { type V1IconType, type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import styled from 'styled-components'
import { type LocalIconType, Icon } from './Icon'

interface CircleProps {
  bgColor?: V1AppColorTheme
  size?: string
}
const Circle = styled.div<CircleProps>`
  background-color: ${(props) =>
    props.bgColor !== undefined ? props.bgColor.color : 'white'};
  border-radius: ${borderRadius.MAX};
  width: ${(props) => (props.size !== undefined ? props.size : '50px')};
  height: ${(props) => (props.size !== undefined ? props.size : '50px')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Center = styled.span`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  icon: LocalIconType | V1IconType
  bgColor?: V1AppColorTheme
  iconColor?: V1AppColorTheme
  roundSize?: string
  size?: FontSizeKeyType
}

const Rounded = (props: Props): JSX.Element => {
  return (
    <>
      <Circle bgColor={props.bgColor} size={props.roundSize}>
        <Center>
          <Icon
            iconType={props.icon}
            iconStyle={{
              fontSize: props.size,
              theme: props.iconColor,
              padding: { all: spaceStyle.AUTO }
            }}
          />
        </Center>
      </Circle>
    </>
  )
}

export default Rounded
