import { fontSize, fontStyle, fontWeight } from '@/styles/font'
import { buildSpaceStyle } from '@/styles/space'
import styled from 'styled-components'
import { type HeadingProps } from './props'
import { memo } from 'react'

const Styled = styled.h1<HeadingProps>`
  font-size: ${(props) => {
    if (props.fixedSPfontSize === true && props.fontSizeSP !== undefined) {
      // same size as SP
      return props.fontSizeSP
    }
    if (props.fontSize !== undefined) {
      return props.fontSize
    }
    return fontSize.S
  }};
  font-weight: ${(props) =>
    props.fontWeight !== undefined ? props.fontWeight : fontWeight.Regular};
  font-style: ${(props) =>
    props.fontStyle !== undefined ? props.fontStyle : fontStyle.Normal};
  text-align: ${(props) => props.textAlign !== undefined && props.textAlign};
  margin: ${(props) => {
    return props.margin !== undefined ? buildSpaceStyle(props.margin) : ''
  }};
  color: ${(props) => {
    return props.textColor !== undefined ? props.textColor.color : 'black'
  }};
  @media (max-width: 767px) {
    font-size: ${(props) =>
      props.fontSizeSP !== undefined ? props.fontSizeSP : fontSize.XS};
  }
  ${(props) =>
    props.maxLines !== undefined &&
    `
    overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${props.maxLines ?? ''};
  -webkit-box-orient: vertical;
    `}
  white-space: ${(props) => {
    return props.whiteSpace !== undefined && props.whiteSpace
  }};
  ${(props) =>
    (props.truncate ?? false) &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`

const BaseHeading = (props: HeadingProps): JSX.Element => {
  const { children } = props
  return <Styled {...props}>{children}</Styled>
}

export default memo(BaseHeading)
