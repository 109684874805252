import { useRoutes } from 'react-router-dom'
import { appRoutes } from './routes/routes'
import { ThemeProvider } from 'react-jss'
import { useApp } from '@/hooks/useApp'
import useFaviconAndMeta from '@/hooks/useFaviconAndMeta'
import Layout from './components/Layout'

export const Index = (): JSX.Element => {
  const element = useRoutes(appRoutes)
  const app = useApp()
  useFaviconAndMeta({ app })

  if (app.isLoading) return <></>

  return (
    <Layout>
      <ThemeProvider theme={app.theme}>{element}</ThemeProvider>
    </Layout>
  )
}
