import { Footer } from '@/components/Footer'
import { useApp } from '@/hooks/useApp'
import { type Navigation } from '@/components/Footer'
import { V1IconType } from 'my-recruiter-app-ts-if'
import { memo, useEffect, useState } from 'react'
import { Block } from '@/components/base/Block'
import { AppPath } from '@/routes/routes'
import { Link, useLocation } from 'react-router-dom'
import AppAssets from '@/styles/app_assets'
import { Text4 } from '@/components/base/Text'
import useIsMobile from '@/hooks/useMobile'

const FooterContainer = (): JSX.Element => {
  const app = useApp()
  const isMobile = useIsMobile()
  const [onlyDesktop, setOnLyDesktop] = useState(false)

  const location = useLocation()

  const footerNavigations =
    app.footerMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        link: item.url ?? ''
      }
    }) ?? []

  useEffect(() => {
    switch (location.pathname) {
      case AppPath.FavoriteJobposting:
      case AppPath.JobSeekerSchedule:
      case AppPath.ChangeID:
      case AppPath.ChangeIDRequested:
      case AppPath.ChangeIDConfirm:
      case AppPath.Setting:
        setOnLyDesktop(true)
        break

      default:
        setOnLyDesktop(false)
    }

    return () => {}
  }, [location.pathname])

  return (
    <Block laptop={onlyDesktop}>
      <Footer
        company={
          [
            AppPath.Entry.toString(),
            AppPath.Privacy.toString(),
            AppPath.Term.toString()
          ].includes(location.pathname) ? (
            <Block>
              <img
                src={AppAssets.icCompanyProfile}
                alt="company logo"
                width={isMobile ? 40 : 80}
                height={isMobile ? 40 : 80}
              />
              <Text4
                fontWeight={isMobile ? '700' : '400'}
                margin={{ all: '0' }}
              >
                会社概要
              </Text4>
            </Block>
          ) : (
            <Link to={AppPath.Top}>
              <img
                src={app.iconImage.url}
                alt="company logo"
                width={app.iconImage.width}
                height={app.iconImage.height}
              />
            </Link>
          )
        }
        footerNavigations={footerNavigations}
        footerCopyright={app.footerCopyright}
      />
    </Block>
  )
}

export default memo(FooterContainer)
