import { authServiceApi } from '@/api/auth-api'

import { type ReactNode } from 'react'
import type React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

export interface UseAuth {
  isLoading: boolean
  isAuthenticated: boolean
  verify: () => Promise<boolean>
  signIn: (props: SignInProps) => Promise<Result>
  signOut: () => Promise<Result>
}

interface SignInProps {
  username: string
  password: string
}

interface Result {
  success: boolean
  message: string
}

const authContext = createContext<UseAuth | undefined>(undefined)

export const ProvideAuth: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = (): UseAuth => {
  const ctx = useContext(authContext)
  if (ctx === undefined) {
    return useProvideAuth()
  }
  return ctx
}

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    const vefify = async (): Promise<void> => {
      const res = await authServiceApi.authServiceVerify({ signal })
      setIsAuthenticated(Boolean(res.isVerify))
      setIsLoading(false)
    }
    void vefify()

    return () => {
      controller.abort()
    }
  }, [])

  const verify = async (): Promise<boolean> => {
    const res = await authServiceApi.authServiceVerify()
    setIsAuthenticated(Boolean(res.isVerify))
    setIsLoading(false)
    return Boolean(res.isVerify)
  }

  const signIn = async ({
    username,
    password
  }: SignInProps): Promise<Result> => {
    await authServiceApi.authServiceSignIn({
      body: { email: username, password }
    })
    return {
      success: true,
      message: 'ログインに成功しました。'
    }
  }

  const signOut = async (): Promise<Result> => {
    try {
      await authServiceApi.authServiceSignOut()
      return {
        success: true,
        message: 'ログアウトに成功しました。'
      }
    } catch {
      return {
        success: false,
        message: 'ログアウトに失敗しました。'
      }
    }
  }

  return {
    isLoading,
    isAuthenticated,
    verify,
    signIn,
    signOut
  }
}
