import { memo } from 'react'
import { PrimaryButton } from '.'
import { type ButtonProps } from './Base'
import { useApp } from '@/hooks/useApp'

const TextButton = (props: ButtonProps): JSX.Element => {
  const app = useApp()
  return (
    <PrimaryButton
      bgColor={app.theme.transparent}
      textColor={app.theme.mainTextColor}
      borderColor={app.theme.transparent}
      shadown={false}
      cursor="pointer"
      hoverBgColor={app.theme.lightGrayColor}
      {...props}
    />
  )
}

export default memo(TextButton)
