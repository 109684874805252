import type React from 'react'
import { memo } from 'react'
import PageLayout from './PageLayout'
interface DefaultLayoutProps {
  children: JSX.Element
}

function Layout({ children }: DefaultLayoutProps): React.ReactElement {
  return <PageLayout>{children}</PageLayout>
}

export default memo(Layout)
