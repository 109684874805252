import styled from 'styled-components'

import { Icon, LocalIconType } from '@/components/base/Icon'
import { useApp } from '@/hooks/useApp'
import { fontSize, fontWeight } from '@/styles/font'
import { cursor } from '@/styles/cursor'
import { device } from '@/styles/device'
import { SecandaryButton, SubButton } from '@/components/base/Button'
import { Block } from '@/components/base/Block'
import spaceStyle, { type SpacingProps, buildSpaceStyle } from '@/styles/space'
import borderRadius from '@/styles/border_radius'
import { Col, Row } from '../Layout'
import { align } from '@/styles/display'
import { buttonSize, type ButtonSizeKeyType } from '../Button/Base'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'

interface StyledPopUpContainerProps {
  padding?: SpacingProps
}

const StyledPopUpContainer = styled.div<StyledPopUpContainerProps>`
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : spaceStyle.XL
  }};

  width: 540px;
  background: white;
  border-radius: ${borderRadius.XL};
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  @media ${device.mobile} {
    width: '80%';
    max-width: 350px;
  }
`

const StyledPopUpCloseButton = styled.button`
  position: absolute;
  top: 13px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
`

interface Button {
  name: string
  width?: string
  height?: ButtonSizeKeyType
  disabled?: boolean
  onClickButton: () => void
  bgColor?: V1AppColorTheme
  textColor?: V1AppColorTheme
}
interface Props {
  children?: JSX.Element
  activeButton?: Button
  inactiveButton?: Button
  onClickClose: () => void
  padding?: SpacingProps
}

const BasePopUp = ({
  children,
  activeButton,
  inactiveButton,
  onClickClose,
  padding
}: Props): JSX.Element => {
  const app = useApp()

  return (
    <StyledPopUpContainer padding={padding}>
      <StyledPopUpCloseButton
        onClick={(e) => {
          e.stopPropagation()
          onClickClose()
        }}
      >
        <Icon
          iconType={LocalIconType.Close}
          iconStyle={{
            fontSize: fontSize.XL,
            theme: app.theme.mainTextColor,
            cursor: cursor.POINTER
          }}
        />
      </StyledPopUpCloseButton>
      <Block margin={{ vertical: spaceStyle.XL }}>{children}</Block>
      <Row gutter={12} justify={align.CENTER}>
        {activeButton !== undefined && (
          <Col
            width={activeButton.width ?? 'auto'}
            sm={24}
            height={activeButton.height ?? buttonSize.M}
          >
            <SecandaryButton
              onClick={activeButton.onClickButton}
              fontWeight={fontWeight.Bold}
              fontSize={fontSize.S}
              height={activeButton.height ?? buttonSize.M}
              disableClickAction={activeButton.disabled}
              bgColor={activeButton.bgColor ?? app.theme.secondaryMainColor}
              textColor={activeButton.textColor ?? app.theme.whiteTextColor}
            >
              {activeButton.name}
            </SecandaryButton>
          </Col>
        )}
        {inactiveButton !== undefined && (
          <Col
            width={inactiveButton.width ?? 'auto'}
            sm={24}
            height={inactiveButton.height ?? buttonSize.M}
          >
            <SubButton
              onClick={inactiveButton.onClickButton}
              fontWeight={fontWeight.Bold}
              fontSize={fontSize.S}
              height={inactiveButton.height ?? buttonSize.M}
              disableClickAction={inactiveButton.disabled}
              bgColor={inactiveButton.bgColor ?? app.theme.whiteTextColor}
              textColor={inactiveButton.textColor ?? app.theme.subTextColor}
            >
              {inactiveButton.name}
            </SubButton>
          </Col>
        )}
      </Row>
    </StyledPopUpContainer>
  )
}

export default BasePopUp
