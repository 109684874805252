import { Block } from '@/components/base/Block'
import Text3 from '@/components/base/Text/Text3'
import spaceStyle from '@/styles/space'
import Company, { type CompanyItem } from '../components/Company'
import { useApp } from '@/hooks/useApp'
import Text2 from '@/components/base/Text/Text2'
import { Link } from 'react-router-dom'
import { AppPath } from '@/routes/routes'

export interface EntryProps {
  title: string
  subTitle: string
  items: CompanyItem[]
}

const EntryContainer = (props: EntryProps): JSX.Element => {
  const { theme, iconImage } = useApp()
  return (
    <>
      <Block mobile>
        <Link to={AppPath.Top}>
          <img
            src={iconImage.url}
            alt="company logo"
            width={iconImage.width}
            height={iconImage.height}
          />
        </Link>
      </Block>
      <Block
        backgroundColor={theme.primaryMainColor}
        padding={{ vertical: '16px', horizontal: '56px' }}
      >
        <Text2
          fontWeight="700"
          whiteSpace="pre-line"
          textColor={theme.whiteColor}
          margin={{ top: '0', horizontal: '0', bottom: '4px' }}
        >
          {props.title}
        </Text2>
        <Text3
          fontWeight="400"
          whiteSpace="pre-line"
          textColor={theme.whiteColor}
          margin={{ all: '0' }}
        >
          {props.subTitle}
        </Text3>
      </Block>
      <Block margin={{ horizontal: 'auto' }} display="inline-block">
        <Block margin={{ vertical: spaceStyle.XL }}>
          {props.items.map((e) => {
            return <Company justify="flex-start" key={e.name} {...e} />
          })}
        </Block>
      </Block>
    </>
  )
}

export default EntryContainer
