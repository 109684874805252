import { Header } from '@/components/Header'
import { type Navigation, type HeaderState } from '@/components/Header/Header'
import SPHeader from '@/components/Header/SPHeader'
import { compareUrlPathWithoutID, PageTitleMap } from '@/components/Layout/util'
import { Block } from '@/components/base/Block'
import { IconButton } from '@/components/base/Button'
import { LocalIconType } from '@/components/base/Icon'
import { useApp } from '@/hooks/useApp'
import { useJobPostingAdvancedSearchConditionBackup } from '@/hooks/useJobPostingAdvancedSearchConditionBackup'
import { useNavigator } from '@/hooks/useNavigator'
import { AppPath } from '@/routes/routes'
import { fontSize } from '@/styles/font'
import {
  V1IconType,
  V1NavigateActionType,
  V1RouteType
} from 'my-recruiter-app-ts-if'
import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const HeaderContainer = (): JSX.Element => {
  const location = useLocation()
  const [showMenuHeader, setShowMenuHeader] = useState(true)
  const [spTitle, setSpTitle] = useState(PageTitleMap[location.pathname])

  useEffect(() => {
    if (
      compareUrlPathWithoutID(
        location.pathname,
        AppPath.JobPostingOfferAcceptance
      ) ??
      false
    ) {
      setShowMenuHeader(false)
      setSpTitle('内定承諾')
    } else {
      switch (location.pathname) {
        case AppPath.Profile:
        case AppPath.JobPostingSearchConditions:
        case AppPath.ChangePassword:
        case AppPath.ForgotPassword:
        case AppPath.ForgotPasswordComplete:
        case AppPath.ConfirmForgotPassword:
        case AppPath.ConfirmForgotPasswordComplete:
        case AppPath.SignUp:
        case AppPath.SignUpComplete:
        case AppPath.SignIn:
        case AppPath.FavoriteJobposting:
        case AppPath.JobSeekerSchedule:
        case AppPath.ChangeID:
        case AppPath.ChangeIDRequested:
        case AppPath.ChangeIDConfirm:
        case AppPath.Setting:
        case AppPath.Entry:
        case AppPath.Privacy:
        case AppPath.Term:
          setShowMenuHeader(false)
          setSpTitle(PageTitleMap[location.pathname])
          break
        default:
          setShowMenuHeader(true)
      }
    }

    return () => {}
  }, [location.pathname])

  return (
    <>
      <Block mobile>
        {showMenuHeader ? (
          <MenuHeaderContainer />
        ) : (
          <SPHeaderContainer title={spTitle} />
        )}
      </Block>
      <Block laptop>
        <MenuHeaderContainer />
      </Block>
    </>
  )
}

interface SPHeaderContainerProps {
  title: string
}

const SPHeaderContainer = (props: SPHeaderContainerProps): JSX.Element => {
  const app = useApp()
  const nav = useNavigator()
  const [showBackButton, setShowBackButton] = useState(true)

  useEffect(() => {
    switch (location.pathname) {
      case AppPath.Entry:
      case AppPath.Privacy:
      case AppPath.Term:
        setShowBackButton(false)
        break
      default:
        setShowBackButton(true)
    }

    return () => {}
  }, [location.pathname])

  return (
    <SPHeader
      title={props.title}
      leading={
        showBackButton ? (
          <IconButton
            icon={LocalIconType.ArrowBackIos}
            color={app.theme.mainTextColor}
            fontSize={fontSize.XXL}
            onClick={() => {
              void nav?.goBack()
            }}
          />
        ) : (
          <Block height="50px" />
        )
      }
    />
  )
}

const MenuHeaderContainer = (): JSX.Element => {
  const app = useApp()
  const nav = useNavigator()
  const [, updateJobPostingAdvancedSearchConditionBackup] =
    useJobPostingAdvancedSearchConditionBackup()

  const iconImage = app.iconImage
  const navigationListPC =
    app.pcHeaderMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        onClick: () => {
          if (
            item.navigateActionType === V1NavigateActionType.GotoSpecificRoute
          ) {
            if (V1RouteType.JobPostingAdvancedSearch === item.routeType) {
              updateJobPostingAdvancedSearchConditionBackup(undefined)
            }
            if (
              V1RouteType.FavoriteJobPostings === item.routeType &&
              item.iconType === V1IconType.History
            ) {
              void nav?.gotoBrowsingHistory()
              return
            }

            void nav?.goToRouteByRouteType(item.routeType)
          }
        }
      }
    }) ?? []

  const navigationListSP =
    app.hamburgerMenu.items?.map((item): Navigation => {
      return {
        name: item.text ?? '',
        icon: item.iconType ?? V1IconType.Unspecified,
        onClick: () => {
          if (
            item.navigateActionType === V1NavigateActionType.GotoSpecificRoute
          ) {
            if (V1RouteType.FavoriteJobPostings === item.routeType) {
              item.iconType === V1IconType.History
                ? nav?.gotoBrowsingHistory()
                : nav?.gotoFavoriteJobposting()

              return
            }

            void nav?.goToRouteByRouteType(item.routeType)
          }
        }
      }
    }) ?? []

  const [headerState, setHeaderState] = useState<HeaderState>({
    isNavigationListOpen: false
  })

  const onClickOpenNavigationList = (): void => {
    setHeaderState({
      isNavigationListOpen: true
    })
  }
  const onClickCloseNavigationList = (): void => {
    setHeaderState({
      isNavigationListOpen: false
    })
  }

  return (
    <Block borderBottom>
      <Header
        headerState={headerState}
        iconImage={iconImage}
        navigationListPC={navigationListPC}
        navigationListSP={navigationListSP}
        onClickOpenNavigationList={onClickOpenNavigationList}
        onClickCloseNavigationList={onClickCloseNavigationList}
      />
    </Block>
  )
}

export default memo(HeaderContainer)
