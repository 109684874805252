const borderRadius = {
  NONE: '0',
  M: '4px',
  L: '8px',
  XL: '12px',
  MAX: '10000px'
} as const

export type BorderRadiusKeyType =
  (typeof borderRadius)[keyof typeof borderRadius]

export interface BorderRadiusProps {
  all?: BorderRadiusKeyType
  top?: BorderRadiusKeyType
  bottom?: BorderRadiusKeyType
  right?: BorderRadiusKeyType
  left?: BorderRadiusKeyType
  upper?: BorderRadiusKeyType
  lower?: BorderRadiusKeyType
}

export const buildBorderRadiusStyle = ({
  all,
  upper,
  lower,
  top,
  right,
  bottom,
  left
}: BorderRadiusProps): string => {
  const none = borderRadius.NONE

  if (all !== undefined) {
    return `${all} ${all} ${all} ${all}`
  }
  if (upper !== undefined) {
    return `${upper} ${upper} ${none} ${none}`
  }
  if (lower !== undefined) {
    return `${none} ${none} ${lower} ${lower}`
  }

  const topValue = top !== undefined ? top : none
  const bottomValue = bottom !== undefined ? bottom : none
  const rightValue = right !== undefined ? right : none
  const leftValue = left !== undefined ? left : none

  return `${topValue} ${rightValue} ${bottomValue} ${leftValue}`
}

export default borderRadius
