import { useEffect } from 'react'
import { type App } from './useApp'

interface useFaviconAndMetaProps {
  app: App
}

const useFaviconAndMeta = ({ app }: useFaviconAndMetaProps): void => {
  const title = app.title ?? ''
  const faviconPath = app.faviconPath ?? ''

  useEffect(() => {
    const favicons = document.querySelectorAll('link[rel="icon"]')
    // icons
    favicons?.forEach((favicon) => {
      const iconSize = favicon.getAttribute('sizes')
      switch (iconSize) {
        case '180x180':
          favicon.setAttribute('href', `${faviconPath}/apple-touch-icon.png`)
          break
        case '32x32':
          favicon.setAttribute('href', `${faviconPath}/favicon-32x32.png`)
          break
        case '16x16':
          favicon.setAttribute('href', `${faviconPath}/favicon-16x16.png`)
          break
        default:
          favicon.setAttribute('href', `${faviconPath}/favicon.ico`)
          break
      }
    })
    // manifest
    const manifest = document.querySelector('link[rel="manifest"]')
    manifest?.setAttribute('href', `${faviconPath}/site.webmanifest`)

    // ページごとに設定する場合は各ページでも対応
    document.title = title
  }, [title, faviconPath])
}

export default useFaviconAndMeta
