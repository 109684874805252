import { type V1FormElementState } from 'my-recruiter-app-ts-if'
import type React from 'react'
import { createContext, useState, type ReactNode, useContext } from 'react'

interface TJobPostingAdvancedSearchConditionBackup {
  formElementStates: V1FormElementState[]
}

type TJobPostingAdvancedSearchConditionBackupContext = [
  undefined | TJobPostingAdvancedSearchConditionBackup,
  React.Dispatch<undefined | TJobPostingAdvancedSearchConditionBackup>
]

export const JobPostingAdvancedSearchConditionBackupContext =
  createContext<TJobPostingAdvancedSearchConditionBackupContext>([
    undefined,
    () => {}
  ])

export const JobPostingAdvancedSearchConditionBackupProvider: React.FC<{
  children?: ReactNode
}> = ({ children }) => {
  const backupKey = 'jp_advanced_search_condition'
  const stateStrOrNull = localStorage.getItem(backupKey)
  let tempState: TJobPostingAdvancedSearchConditionBackup | undefined
  if (stateStrOrNull != null) {
    tempState = JSON.parse(stateStrOrNull)
  }

  const [
    jobPostingAdvancedSearchConditionBackup,
    setJobPostingAdvancedSearchConditionBackup
  ] = useState<TJobPostingAdvancedSearchConditionBackup | undefined>(tempState)

  const context: TJobPostingAdvancedSearchConditionBackupContext = [
    jobPostingAdvancedSearchConditionBackup,
    (newCondition) => {
      setJobPostingAdvancedSearchConditionBackup(newCondition)

      if (newCondition == null) {
        localStorage.removeItem(backupKey)
      } else {
        localStorage.setItem(backupKey, JSON.stringify(newCondition))
      }
    }
  ]

  return (
    <JobPostingAdvancedSearchConditionBackupContext.Provider value={context}>
      {children}
    </JobPostingAdvancedSearchConditionBackupContext.Provider>
  )
}

export function useJobPostingAdvancedSearchConditionBackup(): TJobPostingAdvancedSearchConditionBackupContext {
  return useContext(JobPostingAdvancedSearchConditionBackupContext)
}
