import { memo } from 'react'
import Base, { type ButtonProps } from './Base'
import { useApp } from '@/hooks/useApp'

const Secandary = (props: ButtonProps): JSX.Element => {
  const app = useApp()
  return (
    <Base
      bgColor={app.theme.secondaryMainColor}
      textColor={app.theme.whiteTextColor}
      {...props}
    />
  )
}

export default memo(Secandary)
