import { Loading } from '@/components/base/Loading/Loading'
import { AccessDeniedPage } from '@/feature/Error/pages/403'
import { NotFoundPage } from '@/feature/Error/pages/404'
import PrivacyPage from '@/feature/SingIn/pages/PrivacyPage'
import TermOfUsePage from '@/feature/SingIn/pages/TermOfUsePage'
import { useApp } from '@/hooks/useApp'
import { useAuth } from '@/hooks/useAuth'
import { useNavigator } from '@/hooks/useNavigator'
import { lazy } from 'react'
import { type RouteProps } from 'react-router-dom'

const SignInPage = lazy(
  async () => await import('@/feature/SingIn/pages/SignInPage')
)
const SignUpPage = lazy(
  async () => await import('@/feature/SingUp/pages/SignUpPage')
)
const SinUpCompletePage = lazy(
  async () => await import('@/feature/SingUp/pages/SignUpCompletePage')
)

const ForgotPasswordPage = lazy(
  async () => await import('@/feature/Password/pages/ForgotPasswordPage')
)
const ConfirmForgotPasswordPage = lazy(
  async () => await import('@/feature/Password/pages/ConfirmForgotPasswordPage')
)
const ForgotPasswordCompletePage = lazy(
  async () =>
    await import('@/feature/Password/pages/ForgotPasswordCompletePage')
)
const ConfirmForgotPasswordCompletePage = lazy(
  async () =>
    await import('@/feature/Password/pages/ConfirmForgotPasswordCompletePage')
)

const ChangePasswordPage = lazy(
  async () => await import('@/feature/Password/pages/ChangePasswordPage')
)

const TopPage = lazy(async () => await import('@/feature/Top/pages/TopPage'))

// TODO fix import path and directory structure
const PasswordCompletedPage = lazy(
  async () => await import('@/feature/JobSeeker/pages/PasswordCompletedPage')
)
const PasswordSettingPage = lazy(
  async () => await import('@/feature/JobSeeker/pages/PasswordSettingPage')
)
const FavoriteJobPostingPage = lazy(
  async () => await import('@/feature/JobPosting/pages/FavoriteJobPostingPage')
)
const JobPostingDetailPage = lazy(
  async () => await import('@/feature/JobPosting/pages/JobPostingDetailPage')
)
const JobPostingSearchResultPage = lazy(
  async () =>
    await import('@/feature/JobPosting/pages/JobPostingSearchResultPage')
)
const JobPostingProgressStatusPage = lazy(
  async () =>
    await import('@/feature/JobPosting/pages/JobPostingProgressStatusPage')
)
const JobPostingSearchConditions = lazy(
  async () =>
    await import('@/feature/JobPosting/pages/JobPostingSearchConditionsPage')
)
const JobPostingAdvancedSearchPage = lazy(
  async () =>
    await import('@/feature/JobPosting/pages/JobPostingAdvancedSearchPage')
)

const JobSeekerWithdrawPage = lazy(
  async () => await import('@/feature/JobSeeker/pages/JobSeekerWithdrawPage')
)

const JobSeekerWithdrawCompletePage = lazy(
  async () =>
    await import('@/feature/JobSeeker/pages/JobSeekerWithdrawCompletePage')
)

const JobSeekerSuspendPage = lazy(
  async () => await import('@/feature/JobSeeker/pages/JobSeekerSuspendPage')
)

const JobSeekerSuspendCompletePage = lazy(
  async () =>
    await import('@/feature/JobSeeker/pages/JobSeekerSuspendCompletePage')
)

const JobSeekerInterviewScheduleCollectionPage = lazy(
  async () =>
    await import('@/feature/JobSeeker/pages/InterviewScheduleCollectionPage')
)
const JobSeekerMessageListPage = lazy(
  async () => await import('@/feature/JobSeeker/pages/JobSeekerMessageListPage')
)

const JobSeekerMessageDetailPage = lazy(
  async () =>
    await import('@/feature/JobSeeker/pages/JobSeekerMessageDetailPage')
)
const JobSeekerProfilePage = lazy(
  async () => await import('@/feature/JobSeeker/pages/JobSeekerProfilePage')
)

const ChangeIDPage = lazy(
  async () => await import('@/feature/ChangeID/pages/ChangeIDPage')
)

const ChangeIDRequestedPage = lazy(
  async () => await import('@/feature/ChangeID/pages/ChangeIDRequestedPage')
)

const ChangeIDConfirmPage = lazy(
  async () => await import('@/feature/ChangeID/pages/ChangeIDConfirmPage')
)
const SettingPage = lazy(
  async () => await import('@/feature/Setting/pages/SettingPage')
)
const JobPostingOfferAcceptancePage = lazy(
  async () =>
    await import('@/feature/JobPosting/pages/JobPostingOfferAcceptancePage')
)

const EntryPage = lazy(
  async () => await import('@/feature/SingIn/pages/EntryPage')
)

export enum AppPath {
  Top = '/',
  NotFound = '/404',
  AccessDenied = '/403',
  SignIn = '/sign_in',
  Entry = '/entry',
  Term = '/term',
  Privacy = '/privacy',
  // sign_up
  SignUp = '/sign_up',
  SignUpComplete = '/sign_up/complete',
  // forgot_password
  ForgotPassword = '/forgot_password',
  ForgotPasswordComplete = '/forgot_password/complete',
  ConfirmForgotPassword = '/forgot_password/confirm',
  ConfirmForgotPasswordComplete = '/forgot_password/confirm/complete',
  ChangePassword = '/change_password',
  ChangeID = '/change_sign_in_id',
  ChangeIDRequested = '/change_sign_in_id/requested',
  ChangeIDConfirm = '/change_sign_in_id/confirm',

  // job_postings
  JobPostingDetail = '/job_postings/:id',
  JobPostingAdvancedSearch = '/job_postings/search/advanced',
  JobPostingSearchResult = '/job_postings/search/result',
  JobPostingProgressStatus = '/job_postings/progress_status',
  JobPostingSearchConditions = `/job_postings/search_conditions`,
  BrowsingHistory = `/job_postings/history`,
  FavoriteJobposting = `/job_postings/favorite`,
  JobPostingInquired = `/job_postings/inquired`,
  JobPostingOfferAcceptance = `/job_postings/offer_acceptance/:id`,

  // job_seeker
  JobSeekerSchedule = '/account/schedule',
  JobSeekerMessageList = '/account/messages',
  JobSeekerMessageDetail = '/account/messages/:id',
  JobSeekerWithdraw = '/account/withdraw',
  JobSeekerWithdrawComplete = '/account/withdraw/complete',
  JobSeekerSuspend = '/account/suspend',
  JobSeekerSuspendComplete = '/account/suspend/complete',
  PasswordSetting = '/account/password_setting',
  PasswordSettingComplete = `/account/password_setting/completed`,
  Profile = `/account/profile`,
  Setting = `/account/setting`
}

export function HavePrivateRouteApp(props: RouteProps): JSX.Element {
  const app = useApp()

  const { isLoading } = useAuth()
  if (isLoading) {
    return <Loading />
  }

  if (!app.havePrivatePage) {
    return <NotFoundPage />
  }

  return <>{props.children}</>
}

export function AuthenticatedRoute(props: RouteProps): JSX.Element {
  const { isLoading, isAuthenticated } = useAuth()
  const nav = useNavigator()

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    void nav?.gotoSignIn()
  }

  return isLoading ? <Loading /> : <>{props.children}</>
}

export const appRoutes = [
  {
    path: AppPath.NotFound,
    element: <NotFoundPage />
  },
  {
    path: AppPath.AccessDenied,
    element: <AccessDeniedPage />
  },
  {
    path: AppPath.Top,
    element: (
      <AuthenticatedRoute>
        <TopPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.JobPostingDetail,
    element: (
      <AuthenticatedRoute>
        <JobPostingDetailPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.JobPostingAdvancedSearch,
    element: (
      <AuthenticatedRoute>
        <JobPostingAdvancedSearchPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.JobPostingSearchResult,
    element: (
      <AuthenticatedRoute>
        <JobPostingSearchResultPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.JobPostingSearchConditions,
    element: (
      <AuthenticatedRoute>
        <JobPostingSearchConditions />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.FavoriteJobposting,
    element: (
      <AuthenticatedRoute>
        <FavoriteJobPostingPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.BrowsingHistory,
    element: (
      <AuthenticatedRoute>
        <FavoriteJobPostingPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.JobPostingInquired,
    element: (
      <AuthenticatedRoute>
        <FavoriteJobPostingPage />
      </AuthenticatedRoute>
    )
  },
  {
    path: AppPath.SignUp,
    element: (
      <HavePrivateRouteApp>
        <SignUpPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.SignUpComplete,
    element: (
      <HavePrivateRouteApp>
        <SinUpCompletePage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.SignIn,
    element: (
      <HavePrivateRouteApp>
        <SignInPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.Entry,
    element: (
      <HavePrivateRouteApp>
        <EntryPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.Privacy,
    element: (
      <HavePrivateRouteApp>
        <PrivacyPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.Term,
    element: (
      <HavePrivateRouteApp>
        <TermOfUsePage />
      </HavePrivateRouteApp>
    )
  },

  {
    path: AppPath.ForgotPassword,
    element: (
      <HavePrivateRouteApp>
        <ForgotPasswordPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ForgotPasswordComplete,
    element: (
      <HavePrivateRouteApp>
        <ForgotPasswordCompletePage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ConfirmForgotPassword,
    element: (
      <HavePrivateRouteApp>
        <ConfirmForgotPasswordPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ConfirmForgotPasswordComplete,
    element: (
      <HavePrivateRouteApp>
        <ConfirmForgotPasswordCompletePage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobPostingProgressStatus,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobPostingProgressStatusPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerSchedule,
    element: (
      <HavePrivateRouteApp>
        <JobSeekerInterviewScheduleCollectionPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerMessageList,
    element: (
      <HavePrivateRouteApp>
        <JobSeekerMessageListPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerMessageDetail,
    element: (
      <HavePrivateRouteApp>
        <JobSeekerMessageDetailPage />
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerWithdraw,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobSeekerWithdrawPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerWithdrawComplete,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobSeekerWithdrawCompletePage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerSuspend,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobSeekerSuspendPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobSeekerSuspendComplete,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobSeekerSuspendCompletePage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.PasswordSetting,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <PasswordSettingPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.PasswordSettingComplete,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <PasswordCompletedPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.Profile,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobSeekerProfilePage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ChangePassword,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <ChangePasswordPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ChangeID,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <ChangeIDPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.ChangeIDRequested,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <ChangeIDRequestedPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },

  {
    path: AppPath.ChangeIDConfirm,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <ChangeIDConfirmPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.Setting,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <SettingPage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  },
  {
    path: AppPath.JobPostingOfferAcceptance,
    element: (
      <HavePrivateRouteApp>
        <AuthenticatedRoute>
          <JobPostingOfferAcceptancePage />
        </AuthenticatedRoute>
      </HavePrivateRouteApp>
    )
  }
]
