import { Block } from '@/components/base/Block'
import { Heading3 } from '@/components/base/Heading'
import { Text3 } from '@/components/base/Text'
import { align } from '@/styles/display'
import { fontSize } from '@/styles/font'
import spaceStyle from '@/styles/space'
import { memo } from 'react'

export const NotFoundPage = memo(
  (): JSX.Element => (
    <Block
      textAlign={align.LEFT}
      maxWidth="600px"
      margin={{ horizontal: spaceStyle.AUTO }}
    >
      <Heading3
        fixedSPfontSize
        fontSizeSP={fontSize.L}
        margin={{ vertical: spaceStyle.XL }}
      >
        ご指定のページを表示できません。
      </Heading3>
      <Text3 margin={{ vertical: spaceStyle.NONE }}>
        指定のページは移動、または削除された可能性がございます。もしくは、ご指定のURLが違う可能性がございます。URLをご確認のうえ再度アクセスお願い致します。
        （エラーコード：404）
      </Text3>
    </Block>
  )
)

NotFoundPage.displayName = 'NotFoundPage'
