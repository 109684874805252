import { memo } from 'react'
import Base, { type ButtonProps } from './Base'
import { type App, useApp } from '@/hooks/useApp'
import borderLine, { borderSize } from '@/styles/border'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'

interface Colors {
  bgColor: V1AppColorTheme
  textColor: V1AppColorTheme
  borderColor?: V1AppColorTheme
}

const getColors = (props: ButtonProps, app: App): Colors => {
  const bgColor = props.bgColor ?? app.theme.whiteColor
  const textColor = props.textColor ?? app.theme.mainTextColor
  if (props.outlined ?? false) {
    return {
      bgColor: app.theme.whiteColor,
      textColor: app.theme.mainTextColor,
      borderColor: app.theme.mainTextColor
    }
  }
  return { bgColor, textColor, borderColor: undefined }
}

const Accent = (props: ButtonProps): JSX.Element => {
  const app = useApp()

  const { bgColor, textColor, borderColor } = getColors(props, app)
  const border =
    borderColor !== undefined
      ? { size: borderSize.S, line: borderLine.Solid, color: borderColor }
      : undefined

  return (
    <Base
      {...props}
      bgColor={bgColor}
      textColor={textColor}
      border={border}
      {...props}
    />
  )
}

export default memo(Accent)
