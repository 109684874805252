import { Icon, LocalIconType } from '@/components/base/Icon'
import { useApp } from '@/hooks/useApp'
import { type Navigation } from '@/components/Header'
import { fontSize } from '@/styles/font'
import styled from 'styled-components'
import { Block } from '@/components/base/Block'
import spaceStyle from '@/styles/space'
import { IconButton } from '@/components/base/Button'
import { showErrorToast } from '@/components/SnackBar/BaseSnackBar'
import { useAuth } from '@/hooks/useAuth'
import { AppPath } from '@/routes/routes'
import CommonPopup from '@/components/base/PopUp/CommonPopup'
import { useState } from 'react'

const NavigationListSPBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
`

const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;
`

const NavigationListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
`

interface Props {
  navigationList: Navigation[]
  onClickCloseIcon: () => void
}

const NavigationListSP = ({
  navigationList,
  onClickCloseIcon
}: Props): JSX.Element => {
  const app = useApp()
  const subTextColor = app.theme.subTextColor
  return (
    <NavigationListSPBlock>
      <Block
        margin={{ top: spaceStyle.XXL, left: spaceStyle.S }}
        display="flex"
      >
        <IconButton
          icon={LocalIconType.Close}
          fontSize={fontSize.XXL}
          onClick={onClickCloseIcon}
        />
      </Block>
      <NavigationList>
        {navigationList.map((navigation: Navigation, index) => {
          return (
            <NavigationListItem
              key={index}
              onClick={() => {
                navigation.onClick()
                onClickCloseIcon()
              }}
            >
              <Block display="flex" margin={{ left: spaceStyle.S }}>
                <Icon
                  iconType={navigation.icon}
                  iconStyle={{
                    fontSize: fontSize.XXL,
                    theme: subTextColor
                  }}
                />
                <Block margin={{ left: spaceStyle.S }}>{navigation.name}</Block>
              </Block>
              <Block margin={{ right: spaceStyle.S }}>
                <Icon
                  iconType={LocalIconType.ArrowForwardIos}
                  iconStyle={{
                    fontSize: fontSize.M,
                    theme: subTextColor
                  }}
                />
              </Block>
            </NavigationListItem>
          )
        })}
        <LogoutButtonSP
          navigationList={navigationList}
          onClickCloseIcon={onClickCloseIcon}
        ></LogoutButtonSP>
      </NavigationList>
    </NavigationListSPBlock>
  )
}

const LogoutButtonSP = ({
  navigationList,
  onClickCloseIcon
}: Props): JSX.Element => {
  const app = useApp()
  const { signOut } = useAuth()
  const [isShowPopup, setIsShowPopup] = useState(false)

  const executeSignOut = async (): Promise<void> => {
    const result = await signOut()
    if (result.success) {
      // 状態を破棄することで、ユーザーごとの設定値を再度読み直せるようにしています。
      location.href = AppPath.SignIn
    } else {
      showErrorToast(result.message)
    }
  }

  return (
    <>
      <NavigationListItem
        key={navigationList.length}
        onClick={() => {
          setIsShowPopup(true)
        }}
      >
        <Block display="flex" margin={{ left: spaceStyle.S }}>
          <Icon
            iconType={LocalIconType.Logout}
            iconStyle={{
              fontSize: fontSize.XXL,
              theme: app.theme.subTextColor
            }}
          />
          <Block margin={{ left: spaceStyle.S }}>ログアウト</Block>
        </Block>
        <Block margin={{ right: spaceStyle.S }}>
          <Icon
            iconType={LocalIconType.ArrowForwardIos}
            iconStyle={{
              fontSize: fontSize.M,
              theme: app.theme.subTextColor
            }}
          />
        </Block>
      </NavigationListItem>
      <CommonPopup
        msg="ログアウトします。よろしいですか？"
        display={isShowPopup}
        submitTitle="はい"
        onClose={() => {
          setIsShowPopup(false)
        }}
        onSubmit={() => {
          executeSignOut()
            .then(() => {
              onClickCloseIcon()
            })
            .catch((e) => showErrorToast(e))
        }}
      />
    </>
  )
}

export default NavigationListSP
