import { fontSize, fontWeight } from '@/styles/font'
import BaseHeading from './BaseHeading'
import { type HeadingProps } from './props'
import { memo } from 'react'

const Heading5 = (props: HeadingProps): JSX.Element => {
  return (
    <BaseHeading
      fontSize={fontSize.S}
      fontSizeSP={fontSize.XXS}
      fontWeight={fontWeight.Bold}
      {...props}
    >
      {props.children}
    </BaseHeading>
  )
}

export default memo(Heading5)
