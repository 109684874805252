import { fontSize, fontWeight } from '@/styles/font'
import BaseHeading from './BaseHeading'
import { type HeadingProps } from './props'
import { memo } from 'react'

const Heading4 = (props: HeadingProps): JSX.Element => {
  return (
    <BaseHeading
      fontSize={fontSize.M}
      fontSizeSP={fontSize.XS}
      fontWeight={fontWeight.Bold}
      {...props}
    >
      {props.children}
    </BaseHeading>
  )
}

export default memo(Heading4)
