import {
  type V1GetJobPostingCardsSearchBody,
  type V1JobPostingFilterPanelComponent,
  V1FavoriteStatusType
} from 'my-recruiter-app-ts-if'
import type React from 'react'
import { createContext, useState, type ReactNode, useContext } from 'react'
import { useJobPostingServiceNotifyFavoriteStatusChange } from '@/feature/JobPosting/api/postJobPostingNotifyFavoriteStatusChange'

interface TFavoriteJobPostingIdsStorageContext {
  favoriteJobPostingIds: string[]
  updateFavoriteJobPostingIds: (id: string[]) => void
}

export const FavoriteJobPostingIdsStorageContext =
  createContext<TFavoriteJobPostingIdsStorageContext>({
    favoriteJobPostingIds: [],
    updateFavoriteJobPostingIds: () => {}
  })

interface TJobPostingIdsForBrowsingHistoryStorageContext {
  jobPostingIdsForBrowsingHistory: string[]
  updateJobPostingIdsForBrowsingHistory: (ids: string[]) => void
}

export const JobPostingIdsForBrowsingHistoryStorageContext =
  createContext<TJobPostingIdsForBrowsingHistoryStorageContext>({
    jobPostingIdsForBrowsingHistory: [],
    updateJobPostingIdsForBrowsingHistory: () => {}
  })

export interface TSavedSearchConditionStorage {
  searchData: V1JobPostingFilterPanelComponent
  searchBody: V1GetJobPostingCardsSearchBody
}

interface TSavedJobPostingSearchConditionStorageContext {
  savedJobPostingSearchConditons: TSavedSearchConditionStorage[]
  updateSavedJobPostingSearchConditons: (
    items: TSavedSearchConditionStorage[]
  ) => void
  deleteSavedJobPostingSearchConditons: (index: number) => void
}

export const SavedJobPostingSearchConditionStorageContext =
  createContext<TSavedJobPostingSearchConditionStorageContext>({
    savedJobPostingSearchConditons: [],
    updateSavedJobPostingSearchConditons: () => {},
    deleteSavedJobPostingSearchConditons: () => {}
  })

export const JobPostingStorageProvider: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const favoriteJobPostingIdsKey = 'favorite_job_posting_ids'

  const favoriteJobPostingIdsStrOrNull = localStorage.getItem(
    favoriteJobPostingIdsKey
  )
  let tmpFavoriteJobPostingIds: string[] = []
  if (favoriteJobPostingIdsStrOrNull != null) {
    tmpFavoriteJobPostingIds = JSON.parse(favoriteJobPostingIdsStrOrNull)
  }

  const [favoriteJobPostingIds, setFavoriteJobPostingIds] = useState(
    tmpFavoriteJobPostingIds
  )

  const useJobPostingServiceNotifyFavoriteStatusChangeMutation =
    useJobPostingServiceNotifyFavoriteStatusChange()

  const updateFavoriteJobPostingIds = (ids: string[]): void => {
    if (ids.length > 300) {
      ids = ids.slice(0, 300)
    }

    let favoriteStatus
    let jobPostingId = ''
    if (tmpFavoriteJobPostingIds.length < ids.length) {
      favoriteStatus = V1FavoriteStatusType.Like
      jobPostingId = ids[0]
    } else {
      favoriteStatus = V1FavoriteStatusType.Dislike
      jobPostingId = tmpFavoriteJobPostingIds.filter(
        (id) => !ids.includes(id)
      )[0]
    }

    void useJobPostingServiceNotifyFavoriteStatusChangeMutation
      .mutateAsync({
        jobPostingId,
        body: {
          status: favoriteStatus
        }
      })
      .then(() => {
        setFavoriteJobPostingIds(ids)
        localStorage.setItem(favoriteJobPostingIdsKey, JSON.stringify(ids))
      })
      .catch(async (err) => {
        console.error(err)
      })
  }

  const jobPostingIdsForBrowsingHistoryKey =
    'job_posting_ids_for_browsing_history'

  const jobPostingIdsForBrowsingHistoryStrOrNull = localStorage.getItem(
    jobPostingIdsForBrowsingHistoryKey
  )
  let tmpJobPostingIdsForBrowsingHistory: string[] = []
  if (jobPostingIdsForBrowsingHistoryStrOrNull != null) {
    tmpJobPostingIdsForBrowsingHistory = JSON.parse(
      jobPostingIdsForBrowsingHistoryStrOrNull
    )
  }

  const [jobPostingIdsForBrowsingHistory, setJobPostingIdsForBrowsingHistory] =
    useState(tmpJobPostingIdsForBrowsingHistory)

  const updateJobPostingIdsForBrowsingHistory = (ids: string[]): void => {
    if (ids.length > 100) {
      ids = ids.slice(0, 100)
    }
    localStorage.setItem(
      jobPostingIdsForBrowsingHistoryKey,
      JSON.stringify(ids)
    )
    setJobPostingIdsForBrowsingHistory(ids)
  }

  const savedJobPostingSearchConditonsKey = 'saved_job_posting_search_conditons'

  const savedJobPostingSearchConditonsStrOrNull = localStorage.getItem(
    savedJobPostingSearchConditonsKey
  )
  let tmpSavedJobPostingSearchConditons: TSavedSearchConditionStorage[] = []
  if (savedJobPostingSearchConditonsStrOrNull != null) {
    tmpSavedJobPostingSearchConditons = JSON.parse(
      savedJobPostingSearchConditonsStrOrNull
    )
  }

  const [savedJobPostingSearchConditons, setSavedJobPostingSearchConditons] =
    useState(tmpSavedJobPostingSearchConditons)

  const updateSavedJobPostingSearchConditons = (
    items: TSavedSearchConditionStorage[]
  ): void => {
    if (items.length > 100) {
      items = items.slice(0, 100)
    }
    setSavedJobPostingSearchConditons(items)
    localStorage.setItem(
      savedJobPostingSearchConditonsKey,
      JSON.stringify(items)
    )
  }

  const deleteSavedJobPostingSearchConditons = (index: number): void => {
    let items = savedJobPostingSearchConditons
    if (index >= 0 && index <= items.length) {
      items.splice(index, 1)
    }
    if (items.length > 100) {
      items = items.slice(0, 100)
    }
    setSavedJobPostingSearchConditons(items)
    localStorage.setItem(
      savedJobPostingSearchConditonsKey,
      JSON.stringify(items)
    )
  }

  return (
    <FavoriteJobPostingIdsStorageContext.Provider
      value={{
        favoriteJobPostingIds,
        updateFavoriteJobPostingIds
      }}
    >
      <JobPostingIdsForBrowsingHistoryStorageContext.Provider
        value={{
          jobPostingIdsForBrowsingHistory,
          updateJobPostingIdsForBrowsingHistory
        }}
      >
        <SavedJobPostingSearchConditionStorageContext.Provider
          value={{
            savedJobPostingSearchConditons,
            updateSavedJobPostingSearchConditons,
            deleteSavedJobPostingSearchConditons
          }}
        >
          {children}
        </SavedJobPostingSearchConditionStorageContext.Provider>
      </JobPostingIdsForBrowsingHistoryStorageContext.Provider>
    </FavoriteJobPostingIdsStorageContext.Provider>
  )
}

export function useFavoriteJobPostingIdsStorage(): TFavoriteJobPostingIdsStorageContext {
  return useContext(FavoriteJobPostingIdsStorageContext)
}

export function useJobPostingIdsForBrowsingHistoryStorage(): TJobPostingIdsForBrowsingHistoryStorageContext {
  return useContext(JobPostingIdsForBrowsingHistoryStorageContext)
}

export function useSavedJobPostingSearchConditionStorage(): TSavedJobPostingSearchConditionStorageContext {
  return useContext(SavedJobPostingSearchConditionStorageContext)
}
