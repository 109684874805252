import { ApiConfig } from '@/config'
import {
  Configuration,
  type Middleware,
  type RequestContext,
  type ResponseContext
} from 'my-recruiter-app-ts-if'
import { Configuration as AuthConfiguration } from 'my-recruiter-auth-ts-if'

const middleware: Middleware = {
  pre: async (context: RequestContext) => {
    return {
      init: context.init,
      url: context.url
    }
  },
  post: async (context: ResponseContext) => {
    // レスポンスのステータスコードを取得
    const statusCode = context.response.status
    // レスポンスが204 No Contentの場合
    if (statusCode === 204) {
      return await Promise.resolve(
        new Response('{}', {
          headers: context.response.headers,
          status: 200,
          statusText: 'OK'
        })
      )
    }

    return await Promise.resolve(context.response)
  }
}

export const config = new Configuration({
  basePath: ApiConfig.apiUrl,
  credentials: 'include',
  middleware: [middleware]
})

export const authConfig = new AuthConfiguration({
  basePath: ApiConfig.authApiUrl,
  credentials: 'include',
  middleware: [middleware]
})
