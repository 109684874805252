import { type V1AppColorTheme, V1IconType } from 'my-recruiter-app-ts-if'
import styled from 'styled-components'
import { buildSpaceStyle, type SpacingProps } from '@/styles/space'
import {
  fontSize,
  type FontWeightType,
  type FontSizeKeyType,
  fontWeight
} from '@/styles/font'
import { cursor, type CursorKeyType } from '@/styles/cursor'

interface IconStyleProps {
  fontSize?: FontSizeKeyType
  fontWeight?: FontWeightType
  theme?: V1AppColorTheme
  margin?: SpacingProps
  padding?: SpacingProps
  cursor?: CursorKeyType
}

const StyledIcon = styled.span<IconStyleProps>`
  font-size: ${(props) =>
    props.fontSize !== undefined ? props.fontSize : fontSize.L};
  font-weight: ${(props) =>
    props.fontSize !== undefined ? props.fontWeight : fontWeight.Regular};
  color: ${(props) =>
    props.theme?.color !== undefined ? props.theme?.color : 'black'};
  margin: ${(props) => {
    return props.margin !== undefined ? buildSpaceStyle(props.margin) : ''
  }};
  padding: ${(props) => {
    return props.padding !== undefined ? buildSpaceStyle(props.padding) : ''
  }};
  cursor: ${(props) =>
    props.cursor === undefined ? cursor.DEFAULT : props.cursor};
`

export enum LocalIconType {
  Add = 'add',
  ArrowBackIos = 'arrow_back_ios',
  ArrowForwardIos = 'arrow_forward_ios',
  Close = 'close',
  Delete = 'delete',
  Description = 'description',
  Done = 'done',
  Edit = 'edit',
  EditNote = 'edit_note',
  Email = 'email',
  EventAvailable = 'event_available',
  EventNote = 'event_note',
  ExpandMore = 'expand_more',
  Favorite = 'favorite',
  FavoriteBorder = 'favorite_border',
  FileDownload = 'file_download',
  FileUpload = 'file_upload',
  Filter = 'filter',
  FormatQuote = 'format_quote',
  Group = 'group',
  HelpOutline = 'help_outline',
  Home = 'home',
  HorizontalRule = 'horizontal_rule',
  LocationOn = 'location_on',
  Menu = 'menu',
  NavigateNext = 'navigate_next',
  Notifications = 'notifications',
  Payments = 'payments',
  Person = 'person',
  Schedule = 'schedule',
  Search = 'search',
  South = 'south',
  Textsms = 'textsms',
  Train = 'directions_transit',
  Warning = 'report_problem',
  WorkOut = 'work_outline',
  InBox = 'inbox',
  Inventry2 = 'inventry_2',
  Bluetooth = 'bluetooth',
  Bookmark = 'bookmark',
  BookmarkBorder = 'bookmark_border',
  Bullet = 'bullet',
  FormatListBulleted = 'format_list_bulleted',
  BusinessCenter = 'business_center',
  PhotoCamera = 'photo_camera',
  Block = 'block',
  Sms = 'sms',
  SelectCheckBox = 'select_check_box',
  CheckBox = 'check_box',
  Check = 'check',
  Explore = 'explore',
  CreditCard = 'credit_card',
  Download = 'download',
  AllowDropDown = 'drop_down',
  ContentCopy = 'content_copy',
  EditSquare = 'edit_square',
  Mail = 'mail',
  FilterList = 'filter_list',
  Folder = 'folder',
  Redeem = 'redeem',
  Language = 'language',
  Imagesmode = 'imagesmode',
  Layers = 'layers',
  Lock = 'lock',
  NestEcoLeaf = 'nest_eco_leaf',
  Map = 'map',
  Sort = 'sort',
  Mic = 'mic',
  Remove = 'remove',
  ClearNight = 'clear_night',
  MoreHoriz = 'more_horiz',
  MoreVert = 'more_vert',
  AttachFile = 'attach_file',
  Call = 'call',
  GridView = 'grid_view',
  PlayArrow = 'play_arrow',
  Navigation = 'navigation',
  NearMe = 'near_me',
  Print = 'print',
  AccountCircle = 'account_circle',
  Cached = 'cached',
  Settings = 'settings',
  ExitToApp = 'exit_to_app',
  Share = 'share',
  ShoppingBag = 'shopping_bag',
  ShoppingBasket = 'shopping_basket',
  ShoppingCart = 'shopping_cart',
  Upload = 'upload',
  Visibility = 'visibility',
  VisibilityOff = 'visibility_off',
  Wifi = 'wifi',
  Blank = 'blank',
  ArrowDownward = 'arrow_downward',
  ArrowBack = 'arrow_back',
  ArrowForward = 'arrow_forward',
  ArrowUpward = 'arrow_upward',
  NavigateBefore = 'navigate_before',
  ExpandLess = 'expand_less',
  ZoomInMap = 'zoom_in_map',
  ZoomOutMap = 'zoom_out_map',
  ExpandContent = 'expand_content',
  SyncAlt = 'sync_alt',
  SwapVert = 'swap_vert',
  CalendarMonth = 'calendar_month',
  CheckCircle = 'check_circle',
  Error = 'error',
  DoNotDisturbOn = 'do_not_disturb_on',
  AddCircle = 'add_circle',
  Help = 'help',
  DesktopWindows = 'desktop_windows',
  Computer = 'computer',
  PhoneAndroid = 'phone_android',
  SimCardDownloaded = 'sim_card_downloaded',
  UploadFile = 'upload_file',
  Facebook2 = '2_facebook',
  Instagram2 = '2_instagram',
  Linkedin2 = '2_linkedin',
  Twitter2 = '2_twitter',
  Facebook1 = '1_facebook',
  Instagram1 = '1_instagram',
  Linkedin1 = '1_linkedin',
  Twitter1 = '1_twitter',
  StarShaded = 'star_shaded',
  StarShadedHalf = 'star_shaded_half',
  StarLine = 'star_line',
  ThumpDown = 'thump_down',
  ThumpUp = 'thump_up',
  Cloud = 'cloud',
  Sunny = 'sunny',
  Eco = 'eco',
  Logout = 'logout',
  History = 'history'
}

export interface IconProps {
  iconType: V1IconType | LocalIconType
  iconStyle?: IconStyleProps
  onClick?: () => void
}

const renderStyledIcon = (
  iconType: V1IconType | LocalIconType,
  iconStyle: IconStyleProps | undefined,
  onClick?: () => void
): JSX.Element => (
  <StyledIcon
    {...iconStyle}
    className="material-icons-outlined"
    onClick={onClick}
  >
    {iconType}
  </StyledIcon>
)

const v1ToLocalIconTypeMap: { [key in V1IconType]?: LocalIconType } = {
  [V1IconType.EditDocument]: LocalIconType.Description,
  [V1IconType.Home]: LocalIconType.Home,
  [V1IconType.Search]: LocalIconType.Search,
  [V1IconType.Person]: LocalIconType.Person,
  [V1IconType.LocationOn]: LocalIconType.LocationOn,
  [V1IconType.Payments]: LocalIconType.Payments,
  [V1IconType.Schedule]: LocalIconType.Schedule,
  [V1IconType.Train]: LocalIconType.Train,
  [V1IconType.Work]: LocalIconType.WorkOut,
  [V1IconType.Group]: LocalIconType.Group,
  [V1IconType.FilterAlt]: LocalIconType.Filter,
  [V1IconType.Inbox]: LocalIconType.InBox,
  [V1IconType.Inventory2]: LocalIconType.Inventry2,
  [V1IconType.Bluetooth]: LocalIconType.Bluetooth,
  [V1IconType.Bookmark]: LocalIconType.Bookmark,
  [V1IconType.BookmarkBorder]: LocalIconType.BookmarkBorder,
  [V1IconType.Bullet]: LocalIconType.Bullet,
  [V1IconType.FormatListBulleted]: LocalIconType.FormatListBulleted,
  [V1IconType.BusinessCenter]: LocalIconType.BusinessCenter,
  [V1IconType.PhotoCamera]: LocalIconType.PhotoCamera,
  [V1IconType.Block]: LocalIconType.Block,
  [V1IconType.Sms]: LocalIconType.Sms,
  [V1IconType.SelectCheckBox]: LocalIconType.SelectCheckBox,
  [V1IconType.CheckBox]: LocalIconType.CheckBox,
  [V1IconType.Check]: LocalIconType.Check,
  [V1IconType.Explore]: LocalIconType.Explore,
  [V1IconType.CreditCard]: LocalIconType.CreditCard,
  [V1IconType.Download]: LocalIconType.Download,
  [V1IconType.AllowDropDown]: LocalIconType.AllowDropDown,
  [V1IconType.ContentCopy]: LocalIconType.ContentCopy,
  [V1IconType.EditSquare]: LocalIconType.EditSquare,
  [V1IconType.Edit]: LocalIconType.Edit,
  [V1IconType.Mail]: LocalIconType.Mail,
  [V1IconType.FilterList]: LocalIconType.FilterList,
  [V1IconType.FormatQuote]: LocalIconType.FormatQuote,
  [V1IconType.Folder]: LocalIconType.Folder,
  [V1IconType.Redeem]: LocalIconType.Redeem,
  [V1IconType.Language]: LocalIconType.Language,
  [V1IconType.Imagesmode]: LocalIconType.Imagesmode,
  [V1IconType.Layers]: LocalIconType.Layers,
  [V1IconType.Lock]: LocalIconType.Lock,
  [V1IconType.NestEcoLeaf]: LocalIconType.NestEcoLeaf,
  [V1IconType.Map]: LocalIconType.Map,
  [V1IconType.Sort]: LocalIconType.Sort,
  [V1IconType.Mic]: LocalIconType.Mic,
  [V1IconType.Remove]: LocalIconType.Remove,
  [V1IconType.ClearNight]: LocalIconType.ClearNight,
  [V1IconType.MoreHoriz]: LocalIconType.MoreHoriz,
  [V1IconType.MoreVert]: LocalIconType.MoreVert,
  [V1IconType.AttachFile]: LocalIconType.AttachFile,
  [V1IconType.Call]: LocalIconType.Call,
  [V1IconType.GridView]: LocalIconType.GridView,
  [V1IconType.PlayArrow]: LocalIconType.PlayArrow,
  [V1IconType.Navigation]: LocalIconType.Navigation,
  [V1IconType.NearMe]: LocalIconType.NearMe,
  [V1IconType.Print]: LocalIconType.Print,
  [V1IconType.AccountCircle]: LocalIconType.AccountCircle,
  [V1IconType.Cached]: LocalIconType.Cached,
  [V1IconType.Settings]: LocalIconType.Settings,
  [V1IconType.ExitToApp]: LocalIconType.ExitToApp,
  [V1IconType.Share]: LocalIconType.Share,
  [V1IconType.ShoppingBag]: LocalIconType.ShoppingBag,
  [V1IconType.ShoppingBasket]: LocalIconType.ShoppingBasket,
  [V1IconType.ShoppingCart]: LocalIconType.ShoppingCart,
  [V1IconType.Upload]: LocalIconType.Upload,
  [V1IconType.Visibility]: LocalIconType.Visibility,
  [V1IconType.VisibilityOff]: LocalIconType.VisibilityOff,
  [V1IconType.Wifi]: LocalIconType.Wifi,
  [V1IconType.Blank]: LocalIconType.Blank,
  [V1IconType.ArrowDownward]: LocalIconType.ArrowDownward,
  [V1IconType.ArrowBack]: LocalIconType.ArrowBack,
  [V1IconType.ArrowForward]: LocalIconType.ArrowForward,
  [V1IconType.ArrowUpward]: LocalIconType.ArrowUpward,
  [V1IconType.NavigateBefore]: LocalIconType.NavigateBefore,
  [V1IconType.ExpandLess]: LocalIconType.ExpandLess,
  [V1IconType.ZoomInMap]: LocalIconType.ZoomInMap,
  [V1IconType.ZoomOutMap]: LocalIconType.ZoomOutMap,
  [V1IconType.ExpandContent]: LocalIconType.ExpandContent,
  [V1IconType.SyncAlt]: LocalIconType.SyncAlt,
  [V1IconType.SwapVert]: LocalIconType.SwapVert,
  [V1IconType.CalendarMonth]: LocalIconType.CalendarMonth,
  [V1IconType.CheckCircle]: LocalIconType.CheckCircle,
  [V1IconType.Error]: LocalIconType.Error,
  [V1IconType.DoNotDisturbOn]: LocalIconType.DoNotDisturbOn,
  [V1IconType.AddCircle]: LocalIconType.AddCircle,
  [V1IconType.Help]: LocalIconType.Help,
  [V1IconType.DesktopWindows]: LocalIconType.DesktopWindows,
  [V1IconType.Computer]: LocalIconType.Computer,
  [V1IconType.PhoneAndroid]: LocalIconType.PhoneAndroid,
  [V1IconType.SimCardDownloaded]: LocalIconType.SimCardDownloaded,
  [V1IconType.UploadFile]: LocalIconType.UploadFile,
  [V1IconType.Facebook2]: LocalIconType.Facebook2,
  [V1IconType.Instagram2]: LocalIconType.Instagram2,
  [V1IconType.Linkedin2]: LocalIconType.Linkedin2,
  [V1IconType.Twitter2]: LocalIconType.Twitter2,
  [V1IconType.Facebook1]: LocalIconType.Facebook1,
  [V1IconType.Instagram1]: LocalIconType.Instagram1,
  [V1IconType.Linkedin1]: LocalIconType.Linkedin1,
  [V1IconType.Twitter1]: LocalIconType.Twitter1,
  [V1IconType.StarShaded]: LocalIconType.StarShaded,
  [V1IconType.StarShadedHalf]: LocalIconType.StarShadedHalf,
  [V1IconType.StarLine]: LocalIconType.StarLine,
  [V1IconType.ThumpDown]: LocalIconType.ThumpDown,
  [V1IconType.ThumpUp]: LocalIconType.ThumpUp,
  [V1IconType.Cloud]: LocalIconType.Cloud,
  [V1IconType.Sunny]: LocalIconType.Sunny,
  [V1IconType.Eco]: LocalIconType.Eco,
  [V1IconType.Logout]: LocalIconType.Logout,
  [V1IconType.Add]: LocalIconType.Add,
  [V1IconType.Favorite]: LocalIconType.Favorite,
  [V1IconType.FavoriteBorder]: LocalIconType.FavoriteBorder,
  [V1IconType.History]: LocalIconType.History,
  [V1IconType.EventNote]: LocalIconType.EventNote,
  [V1IconType.EventAvailable]: LocalIconType.EventAvailable
}

const defaultIconType = LocalIconType.HelpOutline

export const Icon = ({
  iconType,
  iconStyle,
  onClick
}: IconProps): JSX.Element => {
  if (Object.values(LocalIconType).includes(iconType as LocalIconType)) {
    return renderStyledIcon(iconType, iconStyle, onClick)
  }

  const localIconType = v1ToLocalIconTypeMap[iconType as V1IconType]

  if (localIconType) {
    return renderStyledIcon(localIconType, iconStyle, onClick)
  }

  return renderStyledIcon(defaultIconType, iconStyle, onClick)
}
