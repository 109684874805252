import {
  QueryClient,
  type UseMutationOptions,
  type DefaultOptions
} from '@tanstack/react-query'
import { type FetchError } from 'my-recruiter-app-ts-if'

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false
  }
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })

type PromiseValue<PromiseType, Otherwise = PromiseType> =
  PromiseType extends Promise<infer Value> ? Value : Otherwise

export type ExtractFnReturnType<FnType extends (...args: any) => any> =
  PromiseValue<ReturnType<FnType>>

export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    FetchError,
    Parameters<MutationFnType>[0]
  >
