import { queryClient } from '@/lib/react-query'
import { QueryClientProvider } from '@tanstack/react-query'
import { Suspense } from 'react'
import { ProvideApp } from './hooks/useApp'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAuth, useAuth } from './hooks/useAuth'
import { JobPostingStorageProvider } from './hooks/useJobPostingStorage'
import { NavigatorProvider } from './hooks/useNavigator'
import { JobPostingAdvancedSearchConditionBackupProvider } from './hooks/useJobPostingAdvancedSearchConditionBackup'
import { Index } from './Index'
import { Loading } from './components/base/Loading/Loading'

export function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <JobPostingStorageProvider>
        <JobPostingAdvancedSearchConditionBackupProvider>
          <BrowserRouter>
            <Suspense>
              <ProvideAuth>
                <WithAuth />
              </ProvideAuth>
            </Suspense>
          </BrowserRouter>
        </JobPostingAdvancedSearchConditionBackupProvider>
      </JobPostingStorageProvider>
    </QueryClientProvider>
  )
}

const WithAuth = (): JSX.Element => {
  const { isLoading } = useAuth()

  if (isLoading) {
    return <Loading />
  }

  return (
    <ProvideApp>
      <NavigatorProvider>
        <Index />
      </NavigatorProvider>
    </ProvideApp>
  )
}
