const authApiDomain = import.meta.env.VITE_AUTH_API_DOMAIN_NAME
const appApiDomain = import.meta.env.VITE_API_DOMAIN_NAME
const appApiPort = import.meta.env.VITE_API_PORT
const appApiSchema = import.meta.env.VITE_API_SCHEMA
const authApiPort = import.meta.env.VITE_AUTH_API_PORT
const authApiSchema = import.meta.env.VITE_AUTH_API_SCHEMA

const appPort = appApiPort == null || appApiPort === '' ? '' : `:${appApiPort}`
const authPort =
  authApiPort == null || authApiPort === '' ? '' : `:${authApiPort}`

let tenant = 'original'
let appApiUrl = ''
let authApiURL = ''

if (
  window.location.hostname.includes('lvh.me') ||
  window.location.hostname.includes('myrecruiter.jp')
) {
  tenant = window.location.hostname.split('.', 3)[0]
  if (tenant == null || tenant === '') {
    console.error(
      `unexpected host(${window.location.hostname}) access. tenant resolved to (${tenant}) `
    )
  }
  appApiUrl = `${authApiSchema}://${tenant}.${appApiDomain}${appPort}`
  authApiURL = `${authApiSchema}://${tenant}.${authApiDomain}${authPort}`
} else {
  appApiUrl = `${appApiSchema}://${window.location.hostname}${appPort}`
  authApiURL = `${appApiSchema}://${window.location.hostname}${authPort}`
}

const isStoryBook = import.meta.env.VITE_RUNNING_STORYBOOK === 'true'

export const ApiConfig = {
  hasApi:
    import.meta.env.VITE_API_SCHEMA !== null &&
    import.meta.env.VITE_API_SCHEMA !== '',
  isStoryBook,
  apiUrl: appApiUrl,
  authApiUrl: authApiURL
}
