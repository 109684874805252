import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'

export const borderSize = {
  NONE: '0px',
  S: '1px'
} as const

export type BorderSizeKeyType = (typeof borderSize)[keyof typeof borderSize]

export const borderLine = {
  NONE: 'none',
  Solid: 'solid'
} as const

export type BorderLineKeyType = (typeof borderLine)[keyof typeof borderLine]

export default borderLine

export interface BorderProps {
  size?: BorderSizeKeyType
  line?: BorderLineKeyType
  color?: V1AppColorTheme
}

export const buildBorderStyle = (props: BorderProps): string => {
  const size = props.size !== undefined ? props.size : borderSize.NONE
  const style = props.line !== undefined ? props.line : borderLine.NONE
  const color = props.color?.color !== undefined ? props.color.color : 'black'

  return `${size} ${style} ${color}`
}

export const commonBorder: BorderProps = {
  size: borderSize.S,
  line: borderLine.Solid
}
